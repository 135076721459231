<template>
  <div>
    <form class="needs-validation" novalidate ref="form" v-on:submit.prevent="submitForm">
      <div class="row">
        <div class="col-12">
          <div class>
            <label for="cardHolderName" class="form-label">ชื่อผู้ถือบัตร</label>
            <input
              type="text"
              class="form-control"
              id="cardHolderName"
              required
              v-model="cardHolder"
              maxlength="50"
            />
            <div class="invalid-feedback">โปรดระบุ</div>
          </div>
        </div>
        <div class="col-12">
          <div class>
            <label for="cardNo" class="form-label">เลขบัตรเครดิตการ์ด</label>
            <input
              type="text"
              class="form-control"
              id="cardNo"
              v-model="cardNo"
              required
              v-mask="'#### - #### - #### - ####'"
            />
            <div class="invalid-feedback">โปรดระบุ</div>
          </div>
        </div>
        <div class="col-6">
          <div class>
            <label for="cardExpires" class="form-label">หมดอายุ (MM/YY)</label>
            <input
              type="text"
              class="form-control"
              id="cardExpires"
              v-model="cardExpires"
              required
              maxlength="5"
              v-mask="'##/##'"
            />
            <div class="invalid-feedback">โปรดระบุ</div>
          </div>
        </div>
        <div class="col-6">
          <div class>
            <label for="cardSecurityCode" class="form-label">รหัสความปลอดภัย</label>
            <input
              type="text"
              class="form-control"
              id="cardSecurityCode"
              v-model="cardSecure"
              required
              maxlength="3"
            />
            <div class="invalid-feedback">โปรดระบุ</div>
            <div id="emailHelp" class="form-text text-info">
              <b-link @click="showGuideSecureCode" id="secureCodeGuideIcon">
                รหัสความปลอดภัยอยู่ตรงไหน
                <b-icon class="ml-1" icon="question-circle"></b-icon>
              </b-link>

              <b-popover :target="`secureCodeGuideIcon`" :placement="'bottom'">
                เลข 3 หลัก ในด้านหลังบัตรเครดิตการ์ดของคุณ
                <div class="pt-2">
                  <b-img
                    v-bind="{ height: 48, width: 'auto' }"
                    src="~@/assets/imgs/secure-code.png"
                  ></b-img>
                </div>
              </b-popover>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" ref="btnSubmit" style="width:0px: height:0px;position:absolute"></button>
    </form>
  </div>
</template>

<script>
import { PlusIcon } from "vue-feather-icons";
export default {
  components: {
    PlusIcon
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    forceSave: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    cardHolder: "",
    cardNo: "",
    cardExpires: "",
    cardSecure: ""
  }),
  methods: {
    showGuideSecureCode() {},
    submitForm() {
      this.$refs.form.classList.add("was-validated");
    },
    validateForm() {
      this.$refs.btnSubmit.click();
      return this.$refs.form.checkValidity();
    },
    getCardInfo() {
      let valid = this.validateForm();
      if (!valid) return null;

      return {
        holderName: this.cardHolder,
        cardNo: this.cardNo,
        expires: this.cardExpires,
        secureCode: this.cardSecure
      };
    },
    clear() {
      this.cardHolder = "";
      this.cardNo = "";
      this.cardExpires = "";
      this.cardSecure = "";
      this.$refs.form.classList.remove("was-validated");
    }
  }
};
</script>