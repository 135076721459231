
/**
 * Index-classic-saas component
 */
import CreditCardForm from "@/components/credit-card-form";
import { PlusIcon, Trash2Icon } from 'vue-feather-icons'
import omiseService from "../../../services/omiseService";
import accountMenu from "@/views/account/menu.vue";
export default {
    components: {
        accountMenu,
        CreditCardForm,
        PlusIcon,
        Trash2Icon
    },
    data: () => ({
        customerCards: [],
        modalCreditCardShow: false,
        overlay: false
    }),
    computed: {
        hasCard() {
            if (this.customerCards.length > 0) {
                return true
            }
            return false
        },
        enableOmiseFeature() {
            return this.$store.state.enableOmiseFeature || false
        }
    },
    mounted() {
        if (this.enableOmiseFeature) {
            this.getCustomerCards();
        }
    },
    methods: {
        getCustomerCards() {
            this.overlay = true
            omiseService
                .listCards()
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        this.customerCards = resp.data.result.data;
                        delete resp.data.result.data;
                        // this.paginationOpts = resp.data.result;
                    } else {
                        throw "Can not get customer's cards";
                    }
                })
                .catch((err) => {
                    this.customerCards = [];
                }).finally(() => {
                    this.overlay = false
                });
        },
        handleCreditCardFormOk(bvModalEvt) {
            bvModalEvt.preventDefault();

            let newCard = this.$refs.creditCardForm.getCardInfo();
            if (newCard != null) {
                this.modalCreditCardShow = false
                this.overlay = true
                this.createOmiseToken(newCard);
            }
        },
        createOmiseToken(newCard) {
            let expires = newCard.expires.split("/");
            let prefixYear = new Date().getFullYear().toString().substr(0, 2);
            let fullYear = `${prefixYear}${expires[1]}`;
            let tokenParameters = {
                expiration_month: +expires[0],
                expiration_year: +fullYear,
                name: newCard.holderName,
                number: newCard.cardNo.replace(/-|\s/g, ""),
                security_code: +newCard.secureCode,
            };
            Omise.createToken("card", tokenParameters, this.createTokenCallback);
        },
        createTokenCallback(statusCode, response) {
            if (response.object == "error") {
                this.overlay = false
                this.$bvModal.msgBoxOk(response.message, {
                    title: "Payment Service Error",
                    size: "sm",
                    okVariant: "danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                });
            } else {
                omiseService.addCard({
                    tokenId: response.id
                }).then((resp) => {
                    this.getCustomerCards()
                }).catch((err) => {
                    this.overlay = false
                    this.$bvModal.msgBoxOk(err.response.message, {
                        title: "Payment Service Error",
                        size: "sm",
                        okVariant: "danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
            }
        },
        deleteCustomerCards(cardId) {
            omiseService
                .deletecard("card_test_5mr8t98bxu4stgcnnd1")
                .then((resp) => {
                    if (resp.data.status == true && resp.data.result != null) {
                        this.customerCards = resp.data.result.data;
                        delete resp.data.result.data;
                        // this.paginationOpts = resp.data.result;
                    } else {
                        throw "Can not get customer's cards";
                    }
                })
                .catch((err) => {
                    this.customerCards = [];
                });
        },
        removeCard(card) {
            this.$bvModal.msgBoxConfirm(`คุณต้องการที่จะลบบัตรเครดิต ?`, {
                title: "โปรดยืนยัน",
                centered: true,
                okVariant: "danger",
                okTitle: "ยืนยัน",
                cancelTitle: "ยกเลิก"
            }).then((result) => {
                // pressed OK button
                if (result === true) {
                    this.overlay = true;
                    omiseService.deletecard(card.id).then((resp) => {
                        if (resp.data.status === true && resp.data.result.deleted == true) {
                            this.getCustomerCards();
                        } else {
                            throw new Error(resp.data.failure_message)
                        }
                    }).catch((err) => {
                        this.overlay = false
                        this.$bvModal.msgBoxOk("Can not remove card due to something went wrong", {
                            title: "Error",
                            centered: true
                        })
                    })
                }
            })
        },
        topupCredit() {
            this.$router.push({
                name: "Point",
                query: {
                    form: true
                }
            })
        }
    },
};
